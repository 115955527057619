<template>
  <v-sheet
    color="white"
    class="pa-3"
    tile
    elevation="1"
  >
    <v-row>
      <v-col
        cols="6"
        md="3"
        class="pr-md-0"
      >
        <v-select
          :value="value.tipo_data"
          :items="tiposDeDatas"
          item-text="descricao"
          item-value="id"
          label="Filtrar Data por"
          hide-details="auto"
          outlined
          dense
          @input="atualizarFiltros('tipo_data', $event)"
        />
      </v-col>
      <v-col
        cols="6"
        md="2"
      >
        <v-menu
          v-model="menuDataInicio"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{}">
            <v-text-field
              v-model="dataInicioFormatada"
              v-mask="'##/##/####'"
              label="Data Início"
              dense
              hide-details="auto"
              outlined
              append-icon="mdi-calendar"
              @blur="atualizarFiltros('data_inicio', formatarData.api($event.target.value))"
              @click:append="menuDataInicio = true"
            />
          </template>
          <v-date-picker
            :value="value.data_inicio"
            no-title
            class="ma-0"
            @input="atualizarFiltrosData('data_inicio', $event)"
          />
        </v-menu>
      </v-col>
      <v-col
        cols="6"
        md="2"
        class="pl-md-0"
      >
        <v-menu
          v-model="menuDataFim"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{}">
            <v-text-field
              v-model="dataFimFormatada"
              v-mask="'##/##/####'"
              label="Data Fim"
              dense
              hide-details="auto"
              outlined
              append-icon="mdi-calendar"
              @blur="atualizarFiltros('data_fim', formatarData.api($event.target.value))"
              @click:append="menuDataFim = true"
            />
          </template>
          <v-date-picker
            :value="value.data_fim"
            no-title
            class="ma-0"
            @input="atualizarFiltrosData('data_fim', $event)"
          />
        </v-menu>
      </v-col>
      <v-col
        cols="6"
        md="2"
        class="pl-md-0"
      >
        <v-text-field
          v-mask="'XXXXXXX'"
          :value="value.placa_veiculo"
          outlined
          dense
          autocomplete="off"
          hide-details="auto"
          clearable
          label="Placa"
          @input="atualizarFiltros('placa_veiculo', $event)"
        />
      </v-col>
      <v-col
        cols="6"
        md="2"
        class="ml-md-2 pt-0 pl-md-0 pt-md-2"
      >
        <v-btn
          color="info"
          class="mt-2"
          :loading="carregando"
          @click="$emit('buscar')"
        >
          <v-icon
            left
            v-text="'mdi-magnify'"
          />
          Buscar
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
  import formatarData from '@/utils/formatar-data-input'

  export default {

    props: {
      value: {
        type: Object,
        default: () => {
          return {}
        },
      },
      carregando: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        menuDataInicio: false,
        menuDataFim: false,
        dataInicioFormatada: null,
        dataFimFormatada: null,
        formatarData,
        tiposDeDatas: [
          {
            id: 1,
            descricao: 'Data da leitura',
          },
          {
            id: 2,
            descricao: 'Data de registro do alerta',
          },
          {
            id: 3,
            descricao: 'Data de recebimento do alerta',
          },
        ],
      }
    },

    watch: {
      'value.data_inicio': {
        immediate: true,
        handler (data) {
          this.dataInicioFormatada = formatarData.ptBr(data)
        },
      },
      'value.data_fim': {
        immediate: true,
        handler (data) {
          this.dataFimFormatada = formatarData.ptBr(data)
        },
      },
    },

    methods: {
      atualizarFiltros (chave, valor) {
        if (!valor) {
          delete this.value[chave]
          return
        }

        const filtros = {
          ...this.value,
          [chave]: valor,
        }

        this.$emit('input', filtros)
      },

      atualizarFiltrosData (chave, valor) {
        this.atualizarFiltros(chave, valor)
        this.menuDataInicio = false
        this.menuDataFim = false
      },

    },
  }
</script>
